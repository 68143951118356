import React from "react"
import { Text } from "theme-ui"
import Layout from "../../components/layout"
import RoleTemplate from "../../components/roles/RoleTemplate"
import Pagination from "../../components/Pagination"

import portraitImage from "../../images/role-portraits/naturalist-cutout.png"
import naturalist01Image from "../../images/ability-cards/naturalist-01.png"
import naturalist02Image from "../../images/ability-cards/naturalist-02.png"
import naturalist03Image from "../../images/ability-cards/naturalist-03.png"
import naturalist04Image from "../../images/ability-cards/naturalist-04.png"

import YAMLData from "../../data/learning-paths/naturalist.yaml"

const NaturalistPage = () => (
  <Layout>
    <RoleTemplate
      name="naturalist"
      src={portraitImage}
      bgGradient="135deg, #8ECB8F, #A2D398"
      introDescription={
        <>
          <Text>The Naturalist whispers to a field of flowers, inspiring them to bloom. They take a wolf’s form, streaking across the steppe. With their hands to the sky, they summon a storm, bringing forth nature’s wrath.</Text>
          <Text>Play The Naturalist if you want to explore an intimate bond with the wild creatures and raw elemental forces of your world.</Text>
        </>
      }
      imgWidth={880}
      imgLeftPos="auto"
      imgRightPos={0}
      abilityDescription="The Naturalist is a powerful magic user who can transform into animal forms and shape the elements. If you want to sling fireballs, call down lightning, summon clouds of fog, or create dazzling auroras in the sky, The Naturalist is the role for you."
      abilityImg01={naturalist01Image}
      abilityImg02={naturalist02Image}
      abilityImg03={naturalist03Image}
      abilityImg04={naturalist04Image}
      yaml={YAMLData}
    />

    <Pagination parent="roles" prev="ranger" next="doctor" />
  </Layout>
)

export default NaturalistPage
